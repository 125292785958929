import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LessonsPage.css';
import Footer from '../components/footer';

const lessons = [
  {
    title: 'Kids Golf Lessons',
    description: 'Learn the basics of golf in a fun and friendly environment. Our beginner lessons cover the fundamentals of the game. For ages 16 and under.',
  },
  {
    title: 'Adult Golf Lessons',
    description: 'Take your golf skills to the next level with our intermediate lessons. Perfect for those looking to improve their game.',
  },
];

const LessonsPage = () => {
  const navigate = useNavigate(); // Using useNavigate for redirection

  useEffect(() => {
    document.body.classList.add('lessons-page');
    return () => {
      document.body.classList.remove('lessons-page');
    };
  }, []);

  return (
    <div className="lessons-page">
      <header className="header">
        <video className="header-video" autoPlay muted loop playsInline>
          <source src="https://swingtt.s3.us-east-2.amazonaws.com/Teaching2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </header>

      <div className="container">
        <h1 className="title">Golf Lessons</h1> {/* Moved the title to the container below the video */}
        <div className="intro">
          <p>Welcome to our golf lessons page. We offer a variety of lessons to improve your game. Ask about lessons, call or message us to make a booking.</p>
        </div>
        <div className="lesson-section">
          {lessons.map((lesson, index) => (
            <div className="card" key={index}>
              <h2>{lesson.title}</h2>
              <p>{lesson.description}</p>
            </div>
          ))}
        </div>

       
      </div>

      <Footer />
    </div>
  );
};

export default LessonsPage;
