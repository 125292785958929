import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import '../styles/booking.css';
import Footer from '../components/footer';

const termsAndConditions = `
  Welcome to Swing Golf Sim and Lounge (“Venue”). These Terms and Conditions govern your use of the Venue's facilities and services. 
  By entering and using the Venue, you agree to comply with and be bound by these Terms and Conditions.

  Advanced booking is recommended to ensure the availability of simulators. Reservations can be made online, by phone, or in person. 
  A valid credit card is required to secure a reservation. Cancellations must be made at least 24 hours in advance to avoid cancellation fees.

  All fees for simulator usage, food, and beverages must be paid in full before leaving the Venue. Prices are subject to change without prior notice. 
  We accept cash, credit, and debit cards.

  Guests must respect the Venue, staff, and other patrons at all times. Any form of harassment, abuse, or disruptive behaviour will not be tolerated 
  and may result in removal from the Venue.

  Guests must follow all instructions provided by staff regarding the use of golf simulators. Guests are responsible for any damages caused to the 
  simulators due to reckless behaviour.

  Outside food and beverages are not permitted. The Venue offers a variety of food and beverages for purchase. Responsible consumption of alcohol 
  is expected; the Venue reserves the right to refuse service to intoxicated guests.

  The Venue is not responsible for any personal injury, loss, or damage to personal property while on the premises. Guests assume all risks associated 
  with the use of the Venue and its facilities.

  Personal information collected during bookings will be used in accordance with our Privacy Policy. We do not share personal information with third parties without consent.

  The Venue reserves the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting on our website or at the Venue.

  These Terms and Conditions shall be governed by and construed in accordance with the laws of Trinidad and Tobago.

  For any questions or concerns regarding these Terms and Conditions, please contact us at swingtthelp@gmail.com.

  By using our facilities, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
`;


const WI_PAY_TAX = 0.035; // WiPay tax rate
const FIXED_USD_FEE = 0.25; // WiPay fixed USD fee
const USD_TO_TTD_RATE = 6.80; // USD to TTD conversion rate

const BookingPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [bookingDetails, setBookingDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: moment().format('YYYY-MM-DD'),
    time: '',
    duration: 1,
    simulator: 1,
    lesson: 0,
  });
  const [maxDuration, setMaxDuration] = useState(1);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  useEffect(() => {
    fetchBookings();
  }, []);

  useEffect(() => {
    if (bookingDetails.date) {
      filterAvailableSlotsForDay(bookingDetails.date);
    }
  }, [selectedDate, bookings, bookingDetails.duration]);

  const fetchBookings = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NGROK}/api/bookings/bookings`);
      setBookings(response.data); // Store all bookings
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const filterAvailableSlotsForDay = (date) => {
    const dayOfWeek = new Date(date).getDay();
    const intervals = generateHourlyIntervals(dayOfWeek);

    const bookingsForDate = bookings.filter(
      (booking) => moment(booking.date).format('YYYY-MM-DD') === date
    );

    const availableSlots = intervals.map((time) => {
      const sim1Available = isSlotAvailable(time, 1, bookingsForDate);
      const sim2Available = isSlotAvailable(time, 2, bookingsForDate);

      return { time, sim1Available, sim2Available };
    });

    setAvailableTimeSlots(availableSlots);
  };

  const calculateMaxDuration = (selectedTime, selectedSimulator) => {
    const combinedDateTime = combineDateTime(bookingDetails.date, selectedTime);
    const closingTime = getClosingTime(combinedDateTime);

    let maxAvailableDuration = Math.floor((closingTime - combinedDateTime) / (60 * 60 * 1000));

    bookings.forEach((booking) => {
      if (booking.simulator === selectedSimulator) {
        const bookingStart = new Date(booking.date);
        const bookingEnd = new Date(bookingStart);
        bookingEnd.setHours(bookingEnd.getHours() + booking.duration);

        if (
          (bookingStart < combinedDateTime && bookingEnd > combinedDateTime) ||
          (bookingStart >= combinedDateTime && bookingStart < closingTime)
        ) {
          const diff = Math.floor((bookingStart - combinedDateTime) / (60 * 60 * 1000));
          maxAvailableDuration = Math.min(maxAvailableDuration, diff);
        }
      }
    });

    setMaxDuration(maxAvailableDuration > 0 ? maxAvailableDuration : 1);
  };

  const isSlotAvailable = (startTime, simulator, bookingsForDate) => {
    const duration = bookingDetails.duration;
    const startMoment = moment(`${bookingDetails.date} ${startTime}`, 'YYYY-MM-DD h:mm A');

    for (let i = 0; i < duration; i++) {
      const checkMoment = startMoment.clone().add(i, 'hours');
      const isBooked = bookingsForDate.some((booking) => {
        const bookingStart = moment(booking.date);
        const bookingEnd = moment(bookingStart).add(booking.duration, 'hours');

        return (
          booking.simulator === simulator &&
          checkMoment.isBetween(bookingStart, bookingEnd, null, '[)')
        );
      });

      if (isBooked) {
        return false;
      }
    }

    return true;
  };

  const generateHourlyIntervals = (dayOfWeek) => {
    const intervals = [];
    const startHour = 11;

    let endHour;
    if (dayOfWeek === 4 || dayOfWeek === 5) {
      endHour = 24; // Friday and Saturday end at midnight
    } else {
      endHour = 23; // Other days end at 11 PM
    }

    for (let i = startHour; i < endHour; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const ampm = i < 12 || i === 24 ? 'AM' : 'PM';
      intervals.push(`${hour}:00 ${ampm}`);
    }
    return intervals;
  };

  // Corrected getClosingTime function
  const getClosingTime = (date) => {
    const dayOfWeek = new Date(date).getDay();
    const closingTime = new Date(date);

    if (dayOfWeek === 5 || dayOfWeek === 6) {
      closingTime.setHours(24, 0, 0, 0); // Midnight (12 AM) for Friday and Saturday
    } else {
      closingTime.setHours(23, 0, 0, 0); // 11 PM for other days
    }

    return closingTime;
  };

  const handleCheckout = () => {
    if (!bookingDetails.firstName || !bookingDetails.lastName || !bookingDetails.email || !bookingDetails.phone || !bookingDetails.date || !bookingDetails.time || !bookingDetails.duration) {
      alert('Please fill in all required fields.');
      return;
    }

    // Show terms popup before proceeding
    setShowTerms(true);
  };

  const proceedWithBooking = async () => {
    if (!isAgreed) {
      alert('You must agree to the terms and conditions.');
      return;
    }

    const { duration, time, date, firstName, lastName, email, phone, simulator } = bookingDetails;

    const combinedDateTime = combineDateTime(date, time);
    const now = new Date();

    if (combinedDateTime <= now) {
      alert('You cannot book for a past date or time.');
      return;
    }

    const oneHourBeforeBooking = new Date(combinedDateTime.getTime() - 60 * 60 * 1000);
    if (now > oneHourBeforeBooking) {
      alert('Bookings must be made at least one hour in advance.');
      return;
    }

    const bookingEndTime = new Date(combinedDateTime);
    bookingEndTime.setTime(combinedDateTime.getTime() + duration * 60 * 60 * 1000);

    const closingTime = getClosingTime(combinedDateTime);
    if (bookingEndTime > closingTime) {
      alert('The selected time slot exceeds the closing time. Please select a different time or reduce the duration.');
      return;
    }

    const peak = isPeakTime(date, time);
    const basePrice = getPricing(peak, date);
    const desiredTotal = basePrice * duration;
    const fixedFeeInTTD = FIXED_USD_FEE * USD_TO_TTD_RATE;
    const adjustedAmount = (desiredTotal - fixedFeeInTTD) / (1 + WI_PAY_TAX);

    const bookingDetailsForSubmission = {
      firstName,
      lastName,
      email,
      phone,
      date: combinedDateTime.toISOString(),
      amount: adjustedAmount.toFixed(2),
      duration,
      simulator,
      lesson: 0,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_NGROK}/api/bookings/initiate`, {
        ...bookingDetailsForSubmission,
        key: '458xf4izac',
        redirect_url: `/api/bookings/confirmation?bookingDetails=${encodeURIComponent(
          JSON.stringify(bookingDetailsForSubmission)
        )}`,
      });

      const { payment_url } = response.data;
      window.location.href = payment_url;
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };

  const isPeakTime = (date, time) => {
    const bookingDate = new Date(date);
    const dayOfWeek = bookingDate.getDay();
    let [timePart, period] = time.split(' ');
    let [hour] = timePart.split(':').map(Number);

    if (period === 'PM' && hour !== 12) hour += 12;
    else if (period === 'AM' && hour === 12) hour = 0;

    // Off-peak hours: Monday to Friday, 11 AM to 5 PM
    if (dayOfWeek >= 0 && dayOfWeek <= 4 && hour >= 11 && hour < 17) {
      return false;
    }

    return true;
  };

  const getPricing = (isPeak, bookingDate) => {
    const cutoffDate = new Date('2024-09-29');
    const isAfterCutoff = new Date(bookingDate) > cutoffDate;

    if (isAfterCutoff) {
      return isPeak ? 600 : 400;
    } else {
      return isPeak ? 300 : 200;
    }
  };

  const combineDateTime = (date, time) => {
    const [hourMinute, ampm] = time.split(' ');
    let [hour, minute] = hourMinute.split(':');
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);

    if (ampm === 'PM' && hour !== 12) {
      hour += 12;
    } else if (ampm === 'AM' && hour === 12) {
      hour = 0;
    }

    return new Date(`${date}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`);
  };

  return (
    <div className="admin-dashboard-container">
      <div className="left-section">
        <h1>Book a Simulator</h1>
        <div className="calendar-container">
          <Calendar
            value={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
              const formattedDate = moment(date).format('YYYY-MM-DD');
              setBookingDetails({ ...bookingDetails, date: formattedDate });
              filterAvailableSlotsForDay(formattedDate);
              setSelectedTimeSlot(null);
            }}
          />
        </div>
        <h3 className="simulator-titles">Available Time Slots</h3>
        <div className="bookings-container">
          <div className="simulator-column">
            <div className="simulator-titles">
              <h3>Sim Zone (Multisport)</h3>
            </div>
            {availableTimeSlots.map((slot, index) => (
              <div key={index} className="time-slot">
                <p
                  className={`${slot.sim1Available ? 'available' : 'unavailable'} ${
                    selectedTimeSlot === `${slot.time}-Sim Zone` ? 'selected' : ''
                  }`}
                  onClick={
                    slot.sim1Available
                      ? () => {
                          setBookingDetails({ ...bookingDetails, time: slot.time, simulator: 1 });
                          setSelectedTimeSlot(`${slot.time}-Sim Zone`);
                          calculateMaxDuration(slot.time, 1);
                        }
                      : null
                  }
                >
                  {slot.time}
                </p>
              </div>
            ))}
          </div>

          <div className="simulator-column">
            <div className="simulator-titles">
              <h3>Golf Den</h3>
            </div>
            {availableTimeSlots.map((slot, index) => (
              <div key={index} className="time-slot">
                <p
                  className={`${slot.sim2Available ? 'available' : 'unavailable'} ${
                    selectedTimeSlot === `${slot.time}-Golf Den` ? 'selected' : ''
                  }`}
                  onClick={
                    slot.sim2Available
                      ? () => {
                          setBookingDetails({ ...bookingDetails, time: slot.time, simulator: 2 });
                          setSelectedTimeSlot(`${slot.time}-Golf Den`);
                          calculateMaxDuration(slot.time, 2);
                        }
                      : null
                  }
                >
                  {slot.time}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="right-section">
  <h3>Create a Booking</h3>
  <div className="form-container">
    <input
      type="text"
      name="firstName"
      placeholder="First Name"
      value={bookingDetails.firstName}
      onChange={(e) => setBookingDetails({ ...bookingDetails, firstName: e.target.value })}
      className="custom-input"
    />
    <input
      type="text"
      name="lastName"
      placeholder="Last Name"
      value={bookingDetails.lastName}
      onChange={(e) => setBookingDetails({ ...bookingDetails, lastName: e.target.value })}
      className="custom-input"
    />
    <input
      type="email"
      name="email"
      placeholder="Email"
      value={bookingDetails.email}
      onChange={(e) => setBookingDetails({ ...bookingDetails, email: e.target.value })}
      className="custom-input"
    />
    <input
      type="tel"
      name="phone"
      placeholder="Phone"
      value={bookingDetails.phone}
      onChange={(e) => setBookingDetails({ ...bookingDetails, phone: e.target.value })}
      className="custom-input"
    />
    <select
      name="duration"
      value={bookingDetails.duration}
      onChange={(e) => setBookingDetails({ ...bookingDetails, duration: parseInt(e.target.value) })}
      className="custom-datepicker"
    >
      {Array.from({ length: maxDuration }, (_, i) => i + 1).map((hour) => (
        <option key={hour} value={hour}>
          {hour} Hour{hour > 1 ? 's' : ''}
        </option>
      ))}
    </select>
    <button className="create-btn" onClick={handleCheckout}>
      Submit Booking
    </button>
  </div>

  {/* Pricing Table */}
  <div className="pricing-table">
    <h3>Pricing</h3>
    <table>
      <thead>
        <tr>
          <th>Service</th>
          <th>11am - 5pm, Monday - Friday</th>
          <th>5pm - Closing, Monday to Friday. Saturday and Sunday</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Simulator Session</td>
          <td>$400</td>
          <td>$600</td>
        </tr>
        <tr>
          <td>Golf Lessons</td>
          <td>$500</td>
          <td>N/A</td>
        </tr>
        <tr>
          <td>Club Rentals</td>
          <td>$50</td>
          <td>$50</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


      {showTerms && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>Terms and Conditions</h2>
            <p>{termsAndConditions}</p>
            <div className="agree-section">
              <label>
                <input type="checkbox" checked={isAgreed} onChange={() => setIsAgreed(!isAgreed)} />
                I agree to the Terms and Conditions
              </label>
            </div>
            <div className="buttons">
              <button className="continue-button" onClick={proceedWithBooking} disabled={!isAgreed}>
                Continue
              </button>
              <button className="close-button" onClick={() => setShowTerms(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};


export default BookingPage;
