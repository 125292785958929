import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import '../styles/OwnerDashboard.css';
import { useNavigate } from 'react-router-dom';

const OwnerDashBoard = () => {
  const [reservations, setReservations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookingForm, setBookingForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    duration: 1,
    simulator: 1,
    lesson: 0, // Default lesson value set to 'None' (0)
  });
  const [editMode, setEditMode] = useState(false);
  const [editingBookingId, setEditingBookingId] = useState(null);
  const [intervals, setIntervals] = useState([]);
  const [maxDuration, setMaxDuration] = useState(1);
  const [originalDuration, setOriginalDuration] = useState(null);
  const [originalTime, setOriginalTime] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    fetchReservations();
  }, [selectedDate]);

  useEffect(() => {
    if (bookingForm.date) {
      const dayOfWeek = new Date(bookingForm.date).getDay();
      
      const reservationsForDay = reservations.filter(
        (res) => moment(res.date).format('YYYY-MM-DD') === bookingForm.date
      );
  
      const availableIntervals = generateHourlyIntervals(
        dayOfWeek,
        reservationsForDay,
        bookingForm.simulator
      );
      setIntervals(availableIntervals);
  
      if (bookingForm.time) {
        const combinedDateTime = combineDateTime(bookingForm.date, bookingForm.time);
        const closingTime = getClosingTime(combinedDateTime);
  
        const availableHours = Math.floor((closingTime - combinedDateTime) / (60 * 60 * 1000));
        setMaxDuration(availableHours > 0 ? availableHours : 1);
      }
    }
  }, [bookingForm.date, bookingForm.time, reservations, bookingForm.simulator]);
 
  const combineDateTime = (date, time) => {
    const [hourMinute, ampm] = time.split(' ');
    let [hour, minute] = hourMinute.split(':');
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);

    if (ampm === 'PM' && hour !== 12) {
      hour += 12;
    } else if (ampm === 'AM' && hour === 12) {
      hour = 0;
    }

    return new Date(`${date}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`);
  };

  const fetchReservations = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_NGROK}/api/admin/bookings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setReservations(data.sort((a, b) => new Date(a.date) - new Date(b.date))); 
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };

  const handleCreateBooking = async () => {
    const { firstName, lastName, email, phone, date, time, duration, simulator, lesson } = bookingForm;
  
    if (!firstName || !lastName || !email || !phone || !date || !time || !duration || !simulator) {
      alert("Please fill in all fields.");
      return;
    }
  
    const combinedDateTime = combineDateTime(date, time);
    const now = new Date();
  
    if (combinedDateTime <= now) {
      alert("You cannot book for a past date or time.");
      return;
    }
  
    const oneHourBeforeBooking = new Date(combinedDateTime.getTime() - 60 * 60 * 1000);
    if (now > oneHourBeforeBooking) {
      alert("Bookings must be made at least one hour in advance.");
      return;
    }
  
    const bookingDetailsForSubmission = {
      firstName,
      lastName,
      email,
      phone,
      date: combinedDateTime.toISOString(),
      duration: duration, // Full duration for the booking
      simulator,
      lesson: lesson || 0,
    };
  
    try {
      if (editMode) {
        const response = await updateBooking(editingBookingId, bookingDetailsForSubmission);
        if (response && response.data.success) {
          alert("Booking updated successfully!");
          fetchReservations();
          resetForm();
        }
      } else {
        await createNewBooking(bookingDetailsForSubmission);
        fetchReservations();
        resetForm();
      }
    } catch (error) {
      console.error('Error creating or updating booking:', error);
    }
  };
  
  const createNewBooking = async (bookingDetails) => {
    try {
      await axios.post(`${process.env.REACT_APP_NGROK}/api/owner/bookings`, bookingDetails);
      alert('Booking created successfully');
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  const updateBooking = async (bookingId, bookingDetails) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_NGROK}/api/owner/bookings/${bookingId}`, {
        ...bookingDetails,
        bookingId
      });
      return response;
    } catch (error) {
      console.error('Error updating booking:', error.response ? error.response.data : error.message);
    }
  };

  const handleEdit = (booking) => {
    const nameParts = booking.name.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(' ');
  
    const time = moment(booking.date).format('hh:mm A');
    setOriginalTime(time);
  
    setEditMode(true);
    setEditingBookingId(booking.id);
  
    setBookingForm({
      firstName: firstName || '',
      lastName: lastName || '',
      email: booking.email || '',
      phone: booking.phone || '',
      date: moment(booking.date).format('YYYY-MM-DD'),
      time: time,
      duration: booking.duration || 1,
      simulator: booking.simulator || 1,
      lesson: booking.lesson || 0,
    });
  
    const availableIntervals = generateAllHourlyIntervals();
    setIntervals(availableIntervals);
  };

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    const combinedDateTime = combineDateTime(bookingForm.date, selectedTime);
  
    setBookingForm((prevDetails) => ({
      ...prevDetails,
      time: selectedTime,
    }));
  
    const dayOfWeek = new Date(bookingForm.date).getDay();
    const reservationsForDay = reservations.filter(
      (res) => moment(res.date).format('YYYY-MM-DD') === bookingForm.date
    );
  
    const availableIntervals = generateHourlyIntervals(dayOfWeek, reservationsForDay, bookingForm.simulator, selectedTime);
    setIntervals(availableIntervals);
  };

  const generateAllHourlyIntervals = () => {
    const intervals = [];
    const startHour = 11;
    const endHour = 22;
  
    for (let i = startHour; i <= endHour; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const ampm = i < 12 ? 'AM' : 'PM';
      const formattedHour = hour < 10 ? `0${hour}` : hour;
      const timeString = `${formattedHour}:00 ${ampm}`;
  
      intervals.push(timeString);
    }
  
    return intervals;
  };

  const generateHourlyIntervals = (dayOfWeek, reservationsForDay, simulator, currentBookingTime = null) => {
    const intervals = [];
    const startHour = 11;
    const endHour = dayOfWeek === 5 || dayOfWeek === 6 ? 23 : 22;
  
    for (let i = startHour; i <= endHour; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const ampm = i < 12 ? 'AM' : 'PM';
      const formattedHour = hour < 10 ? `0${hour}` : hour;
      const timeString = `${formattedHour}:00 ${ampm}`;
  
      if (currentBookingTime && timeString === currentBookingTime) {
        intervals.push(timeString);
      }
  
      intervals.push(timeString);
    }
  
    return intervals;
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('authToken');
    try {
      await fetch(`${process.env.REACT_APP_NGROK}/api/admin/bookings/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchReservations();
    } catch (error) {
      console.error('Error deleting reservation:', error);
    }
  };

  const resetForm = () => {
    setBookingForm({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      date: '',
      time: '',
      duration: 1,
      simulator: 1,
      lesson: 0,
    });
    setEditMode(false);
    setEditingBookingId(null);
    setOriginalDuration(null);
  };

  const getClosingTime = (date) => {
    const closingTime = new Date(date);
    const dayOfWeek = closingTime.getDay();
  
    if (dayOfWeek === 5 || dayOfWeek === 6) {
      closingTime.setHours(24, 0, 0, 0);
    } else {
      closingTime.setHours(23, 0, 0, 0);
    }
  
    return closingTime;
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/admin');
  };

  return (
    <div className="admin-dashboard-container">
      <div className="logout-section">
        <button className="logout-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <div className="left-section">
        <h1>Owner Dashboard</h1>
        <div className="calendar-container">
          <Calendar
            value={selectedDate}
            onChange={setSelectedDate}
            tileContent={({ date }) => {
              const dateString = moment(date).format('YYYY-MM-DD');
              const bookingsForDay = reservations.filter((res) =>
                moment(res.date).format('YYYY-MM-DD') === dateString
              );
              return bookingsForDay.length ? <div>{bookingsForDay.length} booking(s)</div> : null;
            }}
          />
        </div>
        <h3>Bookings for {moment(selectedDate).format('dddd MMM Do YYYY')}</h3>
        <div className="bookings-container">
          <div className="sim-column">
            <div className="simulator-section">
              <h4>Sim Zone (Multisport)</h4>
              {reservations
                .filter((res) => moment(res.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') && res.simulator === 1)
                .map((res) => (
                  <div className="reservation-card" key={res.id}>
                    <h4>{res.firstName} {res.lastName}</h4>
                    <p>Email: {res.email}</p>
                    <p>Phone: {res.phone}</p>
                    <p>Time: {moment(res.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                    <p>Duration: {res.duration} hour(s)</p>
                    <p>Lesson: {res.lesson === 0 ? 'None' : res.lesson === 1 ? 'Kids Lesson' : 'Adult Lesson'}</p>
                    <div className="reservation-buttons">
                      <button className="edit-btn" onClick={() => handleEdit(res)}>Edit</button>
                      <button className="delete-btn" onClick={() => handleDelete(res.id)}>Delete</button>
                    </div>
                  </div>
                ))}
            </div>
            <div className="simulator-section">
              <h4>Golf Den</h4>
              {reservations
                .filter((res) => moment(res.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') && res.simulator === 2)
                .map((res) => (
                  <div className="reservation-card" key={res.id}>
                    <h4>{res.firstName} {res.lastName}</h4>
                    <p>Email: {res.email}</p>
                    <p>Phone: {res.phone}</p>
                    <p>Time: {moment(res.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                    <p>Duration: {res.duration} hour(s)</p>
                    <p>Lesson: {res.lesson === 0 ? 'None' : res.lesson === 1 ? 'Kids Lesson' : 'Adult Lesson'}</p>
                    <div className="reservation-buttons">
                      <button className="edit-btn" onClick={() => handleEdit(res)}>Edit</button>
                      <button className="delete-btn" onClick={() => handleDelete(res.id)}>Delete</button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="right-section">
        <h3>{editMode ? 'Edit Booking' : 'Create Booking'}</h3>
        <div className="form-container">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={bookingForm.firstName}
            onChange={(e) => setBookingForm({ ...bookingForm, firstName: e.target.value })}
            className="custom-input"
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={bookingForm.lastName}
            onChange={(e) => setBookingForm({ ...bookingForm, lastName: e.target.value })}
            className="custom-input"
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={bookingForm.email}
            onChange={(e) => setBookingForm({ ...bookingForm, email: e.target.value })}
            className="custom-input"
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={bookingForm.phone}
            onChange={(e) => setBookingForm({ ...bookingForm, phone: e.target.value })}
            className="custom-input"
          />
          <input
            type="date"
            name="date"
            value={bookingForm.date}
            onChange={(e) => setBookingForm({ ...bookingForm, date: e.target.value })}
            className="custom-datepicker"
          />
          <select
            name="time"
            value={bookingForm.time}
            onChange={handleTimeChange}
            className="custom-datepicker"
          >
            <option value="">Select Time</option>
            {intervals.map((interval, index) => (
              <option key={index} value={interval}>{interval}</option>
            ))}
          </select>
          <select
            name="duration"
            value={bookingForm.duration}
            onChange={(e) => setBookingForm({ ...bookingForm, duration: parseInt(e.target.value) })}
            className="custom-datepicker"
          >
            {Array.from({ length: maxDuration }, (_, i) => i + 1).map((hour) => (
              <option key={hour} value={hour}>{hour} Hour{hour > 1 ? 's' : ''}</option>
            ))}
          </select>
          <select
            name="simulator"
            value={bookingForm.simulator}
            onChange={(e) => setBookingForm({ ...bookingForm, simulator: parseInt(e.target.value) })}
            className="custom-input"
          >
            <option value={1}>Sim Zone</option>
            <option value={2}>Golf Den</option>
          </select>
          <select
            name="lesson"
            value={bookingForm.lesson}
            onChange={(e) => setBookingForm({ ...bookingForm, lesson: parseInt(e.target.value) })}
            className="custom-input"
          >
            <option value={0}>None</option>
            <option value={1}>Kids Lesson</option>
            <option value={2}>Adult Lesson</option>
          </select>
          <button className="create-btn" onClick={handleCreateBooking}>{editMode ? 'Update Booking' : 'Create Booking'}</button>
          {editMode && (
            <button className="cancel-btn" onClick={resetForm}>Cancel Edit</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OwnerDashBoard;
