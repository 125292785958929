import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import '../styles/AdminDashboard.css';
import { useNavigate } from 'react-router-dom';

const WI_PAY_TAX = 0.035; // WiPay tax rate
const FIXED_USD_FEE = 0.25; // WiPay fixed USD fee
const USD_TO_TTD_RATE = 6.80; // USD to TTD conversion rate

const AdminDashboard = () => {
  const [reservations, setReservations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookingForm, setBookingForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    duration: 1,
    simulator: 1,
    lesson: 0, // Default lesson value set to 'None' (0)
  });
  const [editMode, setEditMode] = useState(false);
  const [editingBookingId, setEditingBookingId] = useState(null);
  const [intervals, setIntervals] = useState([]);
  const [maxDuration, setMaxDuration] = useState(1);
  const [originalDuration, setOriginalDuration] = useState(null);
  const [originalTime, setOriginalTime] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    fetchReservations();
  }, [selectedDate]);

  useEffect(() => {
    if (bookingForm.date) {
      // Generate combined datetime for calculating the closing time and available duration
      const dayOfWeek = new Date(bookingForm.date).getDay();
      
      // Filter reservations for the selected day
      const reservationsForDay = reservations.filter(
        (res) => moment(res.date).format('YYYY-MM-DD') === bookingForm.date
      );
  
      // Generate available intervals based on the selected date and simulator
      const availableIntervals = generateHourlyIntervals(
        dayOfWeek,
        reservationsForDay,
        bookingForm.simulator
      );
      setIntervals(availableIntervals); // Update available time intervals
  
      // If a time is selected, calculate available duration
      if (bookingForm.time) {
        const combinedDateTime = combineDateTime(bookingForm.date, bookingForm.time);
        const closingTime = getClosingTime(combinedDateTime);
  
        // Calculate available hours based on the selected time
        const availableHours = Math.floor((closingTime - combinedDateTime) / (60 * 60 * 1000));
        setMaxDuration(availableHours > 0 ? availableHours : 1); // Set available durations
      }
    }
  }, [bookingForm.date, bookingForm.time, reservations, bookingForm.simulator]);
  
  

 
  const combineDateTime = (date, time) => {
    const [hourMinute, ampm] = time.split(' ');
    let [hour, minute] = hourMinute.split(':');
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);

    if (ampm === 'PM' && hour !== 12) {
      hour += 12;
    } else if (ampm === 'AM' && hour === 12) {
      hour = 0;
    }

    return new Date(`${date}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`);
  };

  const fetchReservations = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_NGROK}/api/admin/bookings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setReservations(data.sort((a, b) => new Date(a.date) - new Date(b.date))); 
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };

  
  const handleCreateBooking = async () => {
    const { firstName, lastName, email, phone, date, time, duration, simulator, lesson } = bookingForm;
  
    if (!firstName || !lastName || !email || !phone || !date || !time || !duration || !simulator) {
      alert("Please fill in all fields.");
      return;
    }
  
    const combinedDateTime = combineDateTime(date, time);
    const now = new Date();
  
    if (combinedDateTime <= now) {
      alert("You cannot book for a past date or time.");
      return;
    }
  
    const oneHourBeforeBooking = new Date(combinedDateTime.getTime() - 60 * 60 * 1000);
    if (now > oneHourBeforeBooking) {
      alert("Bookings must be made at least one hour in advance.");
      return;
    }
  
    let totalAmount = 0;
    let priceDifference = 0;
  
    if (editMode) {
      // Calculate the original and new costs
      const newBookingCost = calculateBookingCost(duration, combinedDateTime);
      const originalBookingCost = calculateBookingCost(originalDuration, originalTime);
      console.log("original:", originalDuration);
      // Calculate the additional hours being added (if any)
      const additionalHours = duration - originalDuration;
  
      if (additionalHours > 0) {
        // Charge only for the additional hours
        const additionalBookingTime = combineDateTime(date, time);
        additionalBookingTime.setHours(additionalBookingTime.getHours() + originalDuration);
  
        priceDifference = calculateBookingCost(additionalHours, additionalBookingTime);
      }
  
      console.log("Price Difference:", priceDifference);
  
      if (priceDifference > 0) {
        // If there's an additional cost, initiate WiPay for the difference
        totalAmount = priceDifference;
        const paymentSuccess = await initiateWiPayPayment(totalAmount, {
          firstName,
          lastName,
          email,
          phone,
          date: combinedDateTime.toISOString(),
          duration,
          simulator,
          lesson: lesson || 0,
          bookingId: editingBookingId, // Pass the booking ID when editing
        });
  
        if (paymentSuccess) {
          alert("Payment completed successfully, booking updated!");
          fetchReservations();
          resetForm();
        } else {
          alert("Payment failed, booking not updated.");
        }
      } else {
        // If no additional payment is required, just update the booking
        const response = await updateBooking(editingBookingId, {
          firstName,
          lastName,
          email,
          phone,
          date: combinedDateTime.toISOString(),
          duration,
          simulator,
          lesson: lesson || 0,
        });
  
        if (response && response.data.success) {
          alert("Booking updated successfully without additional payment!");
          fetchReservations();
          resetForm();
        }
      }
    } else {
      // For new bookings, calculate the total amount
      totalAmount = calculateBookingCost(duration, combinedDateTime);
  
      const paymentSuccess = await initiateWiPayPayment(totalAmount, {
        firstName,
        lastName,
        email,
        phone,
        date: combinedDateTime.toISOString(),
        duration,
        simulator,
        lesson: lesson || 0,
      });
  
      if (paymentSuccess) {
        fetchReservations();
        resetForm();
      } else {
        alert("Payment was not successful. Booking was not created.");
      }
    }
  };
  
  
  
  
  
  
  
  const calculateBookingCost = (duration, combinedDateTime) => {
    let totalAmount = 0;
    let currentTime = new Date(combinedDateTime);
  
    for (let i = 0; i < duration; i++) {
      const hour = currentTime.getHours();
      const dayOfWeek = currentTime.getDay();
  
      // Check if current time is peak or off-peak
      const isOffPeak = isOffPeakTime(currentTime, dayOfWeek);
      const rate = isOffPeak ? 400 : 600;
  
      totalAmount += rate;
  
      // Move to the next hour
      currentTime.setHours(currentTime.getHours() + 1);
    }
  
    return totalAmount;
  };
  
  // Helper function to determine if the time is off-peak
  const isOffPeakTime = (currentTime, dayOfWeek) => {
    const hour = currentTime.getHours();
    // Off-peak: Monday to Friday, 11 AM - 5 PM
    return dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 11 && hour < 16;
  };
  



  const updateBooking = async (bookingId, bookingDetails) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_NGROK}/api/admin/bookings/${bookingId}`, {
        ...bookingDetails,
        bookingId  // Include bookingId for validation
      });
  
      // Check if the response contains the payment URL
      if (response && response.data && response.data.payment_url) {
        console.log('Redirecting to payment portal');
        // If payment is required, redirect to WiPay for payment
        window.location.href = response.data.payment_url;
      } else if (response && response.data.success) {
        console.log('Booking updated successfully without payment');
        alert('Booking updated successfully!');
      } else {
        alert('An error occurred while updating the booking.');
      }
    } catch (error) {
      console.error('Error updating booking:', error.response ? error.response.data : error.message);
    }
  };
  
  const initiateWiPayPayment = async (totalAmount, bookingDetails) => {
    const adjustedAmount = (totalAmount - FIXED_USD_FEE * USD_TO_TTD_RATE) / (1 + WI_PAY_TAX);
  
    let formattedDate;
    
    if (typeof bookingDetails.date === 'string') {
      formattedDate = bookingDetails.date;
    } else if (bookingDetails.date instanceof Date) {
      formattedDate = bookingDetails.date.toISOString();
    } else {
      formattedDate = new Date(bookingDetails.date).toISOString();
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_NGROK}/api/bookings/initiated`, {
        firstName: bookingDetails.firstName,
        lastName: bookingDetails.lastName,
        email: bookingDetails.email,
        phone: bookingDetails.phone,
        date: formattedDate,
        amount: adjustedAmount.toFixed(2),
        duration: bookingDetails.duration,
        simulator: bookingDetails.simulator,
        lesson: bookingDetails.lesson,
        bookingId: bookingDetails.bookingId || null, // Pass bookingId for edits
        key: '458xf4izac', // Replace with actual key
        redirect_url: `/api/bookings/confirmation`,
      });
  
      const { payment_url } = response.data;
      window.location.href = payment_url;
  
      return true;  // Payment initiated successfully
    } catch (error) {
      console.error('Error initiating payment:', error.response ? error.response.data : error.message);
      return false;  // Payment initiation failed
    }
  };
  
  const handleEdit = (booking) => {
    const nameParts = booking.name.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(' ');
  
    // Set the time from the booking and format it correctly
    const time = moment(booking.date).format('hh:mm A');
    setOriginalTime(time); // Store the original time for future reference
    setOriginalDuration(booking.duration); 
    // Enable edit mode and set the booking ID
    setEditMode(true);
    setEditingBookingId(booking.id);
  
    // Prefill form with booking data
    setBookingForm({
      firstName: firstName || '',
      lastName: lastName || '',
      email: booking.email || '',
      phone: booking.phone || '',
      date: moment(booking.date).format('YYYY-MM-DD'), // Set date
      time: time, // Set the time
      duration: booking.duration || 1,
      simulator: booking.simulator || 1,
      lesson: booking.lesson || 0,
    });
  
    // Recalculate the intervals, always including the currently booked time
    const availableIntervals = generateAllHourlyIntervals(); // Get all possible intervals
    setIntervals(availableIntervals); // Update intervals with all times
  };
  
  
  
  
  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    const combinedDateTime = combineDateTime(bookingForm.date, selectedTime);
  
    // Update the selected time in the form
    setBookingForm((prevDetails) => ({
      ...prevDetails,
      time: selectedTime,
    }));
  
    // Recalculate available intervals after the time change
    const dayOfWeek = new Date(bookingForm.date).getDay();
    const reservationsForDay = reservations.filter(
      (res) => moment(res.date).format('YYYY-MM-DD') === bookingForm.date
    );
  
    // Regenerate intervals and include the selected time
    const availableIntervals = generateHourlyIntervals(dayOfWeek, reservationsForDay, bookingForm.simulator, selectedTime);
    setIntervals(availableIntervals); // Update intervals with the new time
  };
  
  
  
  
  const generateAllHourlyIntervals = () => {
    const intervals = [];
    const startHour = 11;
    const endHour = 22; // Assuming closing time is 10 PM on weekdays, adjust if necessary
  
    for (let i = startHour; i <= endHour; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const ampm = i < 12 ? 'AM' : 'PM';
      const formattedHour = hour < 10 ? `0${hour}` : hour;
      const timeString = `${formattedHour}:00 ${ampm}`;
  
      intervals.push(timeString);
    }
  
    return intervals;
  };
  
  

  const generateHourlyIntervals = (dayOfWeek, reservationsForDay, simulator, currentBookingTime = null) => {
    const intervals = [];
    const startHour = 11;
    const endHour = dayOfWeek === 5 || dayOfWeek === 6 ? 23 : 22;
  
    for (let i = startHour; i <= endHour; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const ampm = i < 12 ? 'AM' : 'PM';
      const formattedHour = hour < 10 ? `0${hour}` : hour;
      const timeString = `${formattedHour}:00 ${ampm}`;
  
      // Always include the current booking time in the list, even if booked
      if (currentBookingTime && timeString === currentBookingTime) {
        intervals.push(timeString);
      }
  
      // Add time slot regardless of availability
      intervals.push(timeString);
    }
  
    return intervals;
  };
  
  

  const handleDelete = async (id) => {
    const token = localStorage.getItem('authToken');
    try {
      await fetch(`${process.env.REACT_APP_NGROK}/api/admin/bookings/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchReservations();
    } catch (error) {
      console.error('Error deleting reservation:', error);
    }
  };

  const resetForm = () => {
    setBookingForm({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      date: '',
      time: '',
      duration: 1,
      simulator: 1,
      lesson: 0,
    });
    setEditMode(false);
    setEditingBookingId(null);
    setOriginalDuration(null);
  };

  const getClosingTime = (date) => {
    const closingTime = new Date(date);
    const dayOfWeek = closingTime.getDay();
  
    // Weekends (Friday/Saturday), closing time is 24:00, otherwise 23:00
    if (dayOfWeek === 5 || dayOfWeek === 6) {
      closingTime.setHours(24, 0, 0, 0);
    } else {
      closingTime.setHours(23, 0, 0, 0);
    }
  
    return closingTime;
  };
  

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove token from local storage
    navigate('/admin'); // Redirect to admin login page
  };
  return (
    <div className="admin-dashboard-container">
      <div className="logout-section">
        <button className="logout-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <div className="left-section">
        <h1>Admin Dashboard</h1>
        <div className="calendar-container">
          <Calendar
            value={selectedDate}
            onChange={setSelectedDate}
            tileContent={({ date }) => {
              const dateString = moment(date).format('YYYY-MM-DD');
              const bookingsForDay = reservations.filter((res) =>
                moment(res.date).format('YYYY-MM-DD') === dateString
              );
              return bookingsForDay.length ? <div>{bookingsForDay.length} booking(s)</div> : null;
            }}
          />
        </div>
        <h3>Bookings for {moment(selectedDate).format('dddd MMM Do YYYY')}</h3>
        <div className="bookings-container">
          <div className="sim-column">
            <div className="simulator-section">
              <h4>Sim Zone</h4>
              {reservations
                .filter((res) => moment(res.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') && res.simulator === 1)
                .map((res) => (
                  <div className="reservation-card" key={res.id}>
                    <h4>{res.firstName} {res.lastName}</h4>
                    <p>Email: {res.email}</p>
                    <p>Phone: {res.phone}</p>
                    <p>Time: {moment(res.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                    <p>Duration: {res.duration} hour(s)</p>
                    <p>Lesson: {res.lesson === 0 ? 'None' : res.lesson === 1 ? 'Kids Lesson' : 'Adult Lesson'}</p>
                    <div className="reservation-buttons">
                      <button className="edit-btn" onClick={() => handleEdit(res)}>Edit</button>
                      <button className="delete-btn" onClick={() => handleDelete(res.id)}>Delete</button>
                    </div>
                  </div>
                ))}
            </div>
            <div className="simulator-section">
              <h4>Golf Den</h4>
              {reservations
                .filter((res) => moment(res.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') && res.simulator === 2)
                .map((res) => (
                  <div className="reservation-card" key={res.id}>
                    <h4>{res.firstName} {res.lastName}</h4>
                    <p>Email: {res.email}</p>
                    <p>Phone: {res.phone}</p>
                    <p>Time: {moment(res.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                    <p>Duration: {res.duration} hour(s)</p>
                    <p>Lesson: {res.lesson === 0 ? 'None' : res.lesson === 1 ? 'Kids Lesson' : 'Adult Lesson'}</p>
                    <div className="reservation-buttons">
                      <button className="edit-btn" onClick={() => handleEdit(res)}>Edit</button>
                      <button className="delete-btn" onClick={() => handleDelete(res.id)}>Delete</button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="right-section">
        <h3>{editMode ? 'Edit Booking' : 'Create Booking'}</h3>
        <div className="form-container">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={bookingForm.firstName}
            onChange={(e) => setBookingForm({ ...bookingForm, firstName: e.target.value })}
            className="custom-input"
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={bookingForm.lastName}
            onChange={(e) => setBookingForm({ ...bookingForm, lastName: e.target.value })}
            className="custom-input"
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={bookingForm.email}
            onChange={(e) => setBookingForm({ ...bookingForm, email: e.target.value })}
            className="custom-input"
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={bookingForm.phone}
            onChange={(e) => setBookingForm({ ...bookingForm, phone: e.target.value })}
            className="custom-input"
          />
          <input
            type="date"
            name="date"
            value={bookingForm.date}
            onChange={(e) => setBookingForm({ ...bookingForm, date: e.target.value })}
            className="custom-datepicker"
          />
          <select
            name="time"
            value={bookingForm.time}
            onChange={handleTimeChange}
            className="custom-datepicker"
          >
            <option value="">Select Time</option>
            {intervals.map((interval, index) => (
              <option key={index} value={interval}>{interval}</option>
            ))}
          </select>
          <select
            name="duration"
            value={bookingForm.duration}
            onChange={(e) => setBookingForm({ ...bookingForm, duration: parseInt(e.target.value) })}
            className="custom-datepicker"
          >
            {Array.from({ length: maxDuration }, (_, i) => i + 1).map((hour) => (
              <option key={hour} value={hour}>{hour} Hour{hour > 1 ? 's' : ''}</option>
            ))}
          </select>
          <select
            name="simulator"
            value={bookingForm.simulator}
            onChange={(e) => setBookingForm({ ...bookingForm, simulator: parseInt(e.target.value) })}
            className="custom-input"
          >
            <option value={1}>Sim Zone</option>
            <option value={2}>Golf Den</option>
          </select>
          <select
            name="lesson"
            value={bookingForm.lesson}
            onChange={(e) => setBookingForm({ ...bookingForm, lesson: parseInt(e.target.value) })}
            className="custom-input"
          >
            <option value={0}>None</option>
            <option value={1}>Kids Lesson</option>
            <option value={2}>Adult Lesson</option>
          </select>
          <button className="create-btn" onClick={handleCreateBooking}>{editMode ? 'Update Booking' : 'Create Booking'}</button>
          {editMode && (
            <button className="cancel-btn" onClick={resetForm}>Cancel Edit</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
